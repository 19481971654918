<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="5" :sm="24">
              <div class="table-operator">
                <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
                <a-button type="primary" icon="import" @click="handleImport">导入</a-button>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="goods_info" slot-scope="text, record">
          {{ text }} ({{ record.barcode }})
        </div>
        <div slot="money_render" slot-scope="text">
          {{ (text / 100).toFixed(2) | numberFormat }}
        </div>
        <span slot="action" slot-scope="text, record">
          <template>
            <a-popconfirm
              title="确定要删除么？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelete(record)"
            >
              <a>删除</a>
            </a-popconfirm>
          </template>
        </span>
      </s-table>
    </a-card>
    <create-discount-form
      ref="createModal"
      v-if="visible"
      :visible.sync="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <create-discount-import-form
      ref="discountImportModal"
      v-if="import_form_visible"
      :visible.sync="import_form_visible"
      :loading="importConfirmLoading"
      @cancel="handleImportCancel"
      @ok="handleImportOk" />
  </div>
</template>

<script>
import { STable } from '@/components'
import {
  createGoodsDiscount,
  putGoodsDiscount,
  goodsDiscountList,
  deleteGoodsDiscount,
  goodsDiscountImport
} from '@/api/applet_shop_goods_discount'
import CreateDiscountForm from './CreateDiscountForm'
import CreateDiscountImportForm from './CreateDiscountImportForm'
import moment from 'moment'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateDiscountForm,
    CreateDiscountImportForm
  },
  data () {
    return {
      loading: false,
      visible: false,
      import_form_visible: false,
      confirmLoading: false,
      importConfirmLoading: false,
      mdl: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '折扣名称',
          ellipsis: true,
          dataIndex: 'name'
        },
        {
          title: '商品',
          ellipsis: true,
          dataIndex: 'goods_name',
          scopedSlots: { customRender: 'goods_info' }
        },
        {
          title: '数量',
          ellipsis: true,
          dataIndex: 'count'
        },
        {
          title: '折后价',
          ellipsis: true,
          dataIndex: 'price',
          scopedSlots: { customRender: 'money_render' }
        },
        {
          title: '开始时间',
          ellipsis: true,
          dataIndex: 'start_date'
        },
        {
          title: '结束时间',
          ellipsis: true,
          dataIndex: 'end_date'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return goodsDiscountList(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  methods: {
    handleCreate () {
      this.mdl = null
      this.visible = true
      this.confirmLoading = false
      // this.$router.push({ name: 'CreateDeliveryAddress' })
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleOk () {
      const form = this.$refs.createModal.form
      form.validateFields((errors, values) => {
        if (!errors) {
          this.confirmLoading = true
          values.price = Math.round(values.price * 100)
          values.start_date = moment(values.start_date).format('YYYY-MM-DD')
          values.end_date = moment(values.end_date).format('YYYY-MM-DD')
          if (this.mdl && this.mdl.id) {
            putGoodsDiscount(values, this.mdl.id).then((res) => {
              this.visible = false
              this.confirmLoading = false
              this.$refs.table.refresh()
            }).catch(errors => {
              values.price = (values.price / 100).toFixed(2)
            }).finally(() => {
              this.confirmLoading = false
            })
          } else {
            console.log('提交的数据----', values)
            createGoodsDiscount(values).then((res) => {
              this.visible = false
              this.confirmLoading = false
              this.$refs.table.refresh()
            }).catch(errors => {
              values.price = (values.price / 100).toFixed(2)
            }).finally(() => {
              this.confirmLoading = false
            })
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleDelete (item) {
      console.log(item)
      deleteGoodsDiscount(item.id).then((res) => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    },
    handleImport () {
      this.import_form_visible = true
    },
    handleImportCancel () {
      this.import_form_visible = false
      const form = this.$refs.discountImportModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleImportOk (fileList) {
      const form = this.$refs.discountImportModal.form
      this.importConfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          const formData = new FormData()
          formData.append('file', fileList[0])
          formData.append('name', values['name'])
          formData.append('count', values['count'])
          goodsDiscountImport(formData).then(res => {
            console.log(res)
            // 刷新表格
            this.$refs.table.refresh()
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
          }).finally(() => {
            this.import_form_visible = false
            this.importConfirmLoading = false
            form.resetFields()
          })
        } else {
          this.importConfirmLoading = false
        }
      })
    }
  }
}
</script>
